import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { FormPKW } from "../../components/FormPKW"
import { NewFooter } from "../../components/NewFooter"
import { Banner } from "../../components/Banners/BannerNewYear"
import { getPageData } from "../../helpers/getPageData"
import { TwoChoice } from "../../components/twoChoice"

import { PageData } from "../../interfaces/pageProps"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { Partners } from "../../components/PartnersNew"
import { BottomCta } from "../../components/BottomCta"
import NewYearImg from "../../components/BottomCta/BgImg/NewYearImg"

const titleForm = (
  <>
    Оформите карту сейчас <br /> и примите участие в розыгрыше
  </>
)

const bottomTitle = (
  <>
    <b>Не упустите свой шанс!</b>
  </>
)

const firstBlockChoise = (
  <>
    Есть Халва – начинай игру <br />
    прямо сейчас!
    <br />
    <span>Главный приз – автомобиль</span>
  </>
)

const subTitlePartners =
  "Совершайте покупки и участвуйте в розыгрыше крупных призов – путешествий, fashion и beauty боксов, техники, ювелирных изделий и АВТОМОБИЛЯ!"

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const countDownTime = 1800000
  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  const seoData = {
    title: "Бесплатная доставка карты Халва",
  }

  return (
    <Layout seoData={seoData} noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint countDownTime={countDownTime} newYear />
      <Banner orderNum="1" />
      <FourAdvantagesPoints withTitle variant="newYear" orderNum="2" />
      <HowInstallmentWorks
        orderNum="3"
        variant="newYear"
        headTitle={<>Как принять участие в розыгрыше автомобиля</>}
      />
      <FormPKW
        title={titleForm}
        dataLayerName="shortPersonalForm"
        formBtnText="Оформить карту"
        orderNum="4"
        emptyForm
        backgroundColor="#60D6A9"
        variant="newYear"
        countDownTime={countDownTime}
        withTimer
        longTitle
      />
      <Partners
        title="Более 250&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="5"
        variant="newYear"
      />
      <TwoChoice newYear orderNum="6" textBlockTitle={firstBlockChoise} />
      <BottomCta orderNum="7" BgImg={NewYearImg} variant="newYear" title={bottomTitle} newYear />
      <NewFooter ligal={ligal} orderNum="8" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/pkw/novyi-god-halva/") {
        url
        notIndex
        ligal {
          text
        }
      }
    }
  }
`
