// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--hOnE5";
export var btnBlock = "banner-module--btnBlock--GafZv";
export var container = "banner-module--container--qTorF";
export var hideMobBlock = "banner-module--hideMobBlock--UbbhM";
export var img = "banner-module--img--B4Spl";
export var mobBtn = "banner-module--mobBtn--8VUWQ";
export var red = "banner-module--red--Bep2e";
export var secondContainer = "banner-module--secondContainer--Ph5zh";
export var section = "banner-module--section--o2Nbw";
export var someDate = "banner-module--someDate--igyio";
export var text = "banner-module--text--y5mrj";