import React from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"
import { TypeBannerProps } from "../helpers"

const defaultSubtext = `
— до 36 месяцев рассрочки <br/>
— до 5000 Р кэшбэка <br/>
— подарки от партнеров и главный приз — автомобиль 
`

export function Banner({
  buttonText = "Оформить карту",
  description = defaultSubtext,
  orderNum,
}: TypeBannerProps) {
  return (
    <>
      <section className={styles.section} data-exclude={orderNum}>
        <Container className={styles.container}>
          <p className={styles.someDate}>04.12-14.01</p>
          <div className={styles.secondContainer}>
            <Img alt="bg image" className={styles.img} />
            <div className={styles.text}>
              <h1>
                Новогодний бум <br />
                <span>с Халвой</span>
              </h1>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={styles.btnBlock}>
        <Container>
          <Button onClick={() => handleClickBanner("click_cta")} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </>
  )
}
